import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatDialogModule]
})
export class UserInputComponent implements OnInit {
    form: UntypedFormGroup;

    constructor(private _dialogRef: MatDialogRef<UserInputComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserInputComponentData,
        private _fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this._fb.group({
            response: ['']
        });
    }

    submit() {
        this._dialogRef.close(this.form.get('response').value);
    }
}

export class UserInputComponentData {
    label?: string;
}
