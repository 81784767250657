<form fxLayout="column" (ngSubmit)="sumbit()" [formGroup]="form" >
    <div mat-dialog-content class="flex flex-col">
        <h2 class="text-black">{{data?.label ? data.label : 'Input Credentials'}}</h2>

        <div>
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input formControlName="username" type="text" name="something" autocomplete="off" matInput>
                <mat-error *ngIf="form.get('username').touched && form.get('username').errors" >Username is required</mat-error>
            </mat-form-field>
        </div>
       
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input formControlName="password" type="password" name="something2" autocomplete="off" matInput >
                <mat-error *ngIf="form.get('password').touched && form.get('password').errors" >Password is required</mat-error>
            </mat-form-field>
        </div>
        

        <button class="w-full" mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
            Submit
        </button>
    </div>
</form>
