import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-credential-input',
  templateUrl: './credential-input.component.html',
  styleUrls: ['./credential-input.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatButtonModule, CommonModule, MatDialogModule]
})
export class CredentialInputComponent implements OnInit {
    form: UntypedFormGroup;

    constructor(private _fb: UntypedFormBuilder,
        private _ref: MatDialogRef<CredentialInputComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CredentialInputData) {}

    ngOnInit(): void {
        this._initForm();
    }

    sumbit() {
        const username: string = this.form.get('username').value;
        const password: string = this.form.get('password').value;

        if(!username?.length) {
            return;
        }

        if(!password?.length) {
            return;
        }

        const creds: UserCredentials = {
            username: username,
            password: password
        };

        this._ref.close(creds);
    }

    private _initForm() {
        this.form = this._fb.group({
            username: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(24)]],
            password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(48)]]
        });
    }
}

export class CredentialInputData {
    label?: string;
}

export class UserCredentials {
    username: string;
    password: string;
}
