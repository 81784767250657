<div mat-dialog-content>
    <form (ngSubmit)="submit()" [formGroup]="form" >
        <div class="flex flex-col">
            <h2 class="text-black">{{data?.label ? data.label : 'Input Response'}}</h2>
                <mat-form-field appearance="outline">
                    <mat-label>Response</mat-label>
                    <input formControlName="response" type="text" name="something" autocomplete="off" matInput>
                </mat-form-field>
            <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
                Submit
            </button>
        </div>
    </form>
</div>