import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CredentialInputComponent, UserCredentials } from '../credential-input/credential-input.component';
import { UserInputComponent } from '../user-input/user-input.component';

@Injectable({
    providedIn: 'root'
})
export class PromptService {
    private _defaultDuration = 4000;
    private _isProd: boolean = environment.production;

    constructor(private snackBar: MatSnackBar, private _modal: MatDialog, private _router: Router) {}

    message(message: string, duration?: number) {
        this.snackBar.open(message, 'dismiss', {
            duration: duration == null ? this._defaultDuration : duration,
            panelClass: ['white-smoke-background', 'text-black']
        });
    }

    success(message: string, duration?: number) {
        this.snackBar.open(message, 'dismiss', {
            duration: duration == null ? this._defaultDuration : duration,
            panelClass: ['text-green', 'white-smoke-background']
        });
    }

    error(error: any, duration?: number, navigateToHomeOnError: boolean = false) {
        if (!this._isProd) console.log(error);

        let errorMsg = this._isProd ? error.error : error.error;
        if(typeof(error) === 'string') errorMsg = error;

        this.snackBar.open(errorMsg, 'dismiss', {
            duration: duration == null ? this._defaultDuration : duration,
            panelClass: ['white-smoke-background', 'text-red']
        });

        if(navigateToHomeOnError) this._router.navigate(['/']);
    }

    getUserCredentials(label: string, reason?: string): MatDialogRef<CredentialInputComponent, UserCredentials> {
        return this._modal.open(CredentialInputComponent, {data: {label: label}});
    }

    getUserInput(label: string): MatDialogRef<UserInputComponent> {
        return this._modal.open(UserInputComponent, {data: {label: label}});
    }
}
